<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      top="5vh"
      width="1056px"
    >
      <div slot="title" class="title">参数下发</div>
      <div class="a-flex-rsbc">
        <div class="a-flex-rfsc">
          <div
            v-for="item in devTypeList"
            :key="item.id"
            class="tab-item a-flex-rfsc"
            :class="tabs == item.id ? 'tabsAc' : ''"
          >
            <div @click="changeTabs(item.id)">{{ item.name }}</div>
          </div>
        </div>
        <span class="tips">* 切换选项卡设置不同设备参数</span>
      </div>
      <!-- 参数表单 -->
      <dev-param-form2
        v-show="tabs == 1"
        ref="form2"
        v-model="form2"
        :set-type="setType"
        :device-id="deviceId"
      ></dev-param-form2>
      <dev-param-form10
        v-show="tabs == 2"
        ref="form10"
        v-model="form10"
        :set-type="setType"
        :device-id="deviceId"
      ></dev-param-form10>
      <dev-param-form12
        v-show="tabs == 3"
        ref="form12"
        v-model="form12"
        :set-type="setType"
        :device-id="deviceId"
      ></dev-param-form12>
      <div slot="footer">
        <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">下发</el-button>
      </div>
    </el-dialog>
    <a-poppup ref="aPoppup">
      <div class="pop-content a-flex-rcc">
        <!-- <el-progress class="progress" :stroke-width="8" :percentage="percentage" color="#007AFF" :show-text="false"></el-progress> -->
        <div class="progress">
          <div class="progress-item"></div>
        </div>
      </div>
    </a-poppup>
  </div>
</template>

<script>
import APoppup from "../../../components/poppup/a-poppup.vue";
import DevParamForm2 from "./dev-param-form2.vue";
import DevParamForm10 from "./dev-param-form10.vue";
import DevParamForm12 from "./dev-param-form12.vue";
export default {
  components: { DevParamForm2, DevParamForm10, DevParamForm12, APoppup },
  data() {
    return {
      dialogVisible: false,
      devDatas: [], //所选设备数据
      devCodes: [], //设备号数据
      devTypeList: [],
      tabs: "", //
      form2: "", //"二路"类型参数
      form10: "", //"十路"类型参数
      form12: "", //"12路"类型参数
      setType: "", //设置类型,是否为第二次设置(重试)
      deviceId: "",
    };
  },
  watch: {
    devDatas(val) {
      const arr = val;
      this.devCodes = [];
      this.devTypeList = [];
      arr.map((item) => {
        this.devCodes.push(item.deviceCode);
      });
      const dev2 = arr.find((res) => {
        return res.deviceType == 1 || res.deviceType == 2;
      });
      const dev10 = arr.find((res) => {
        return res.deviceType == 5;
      });
      const dev12 = arr.find((res) => {
        return res.deviceType == 3 || res.deviceType == 4;
      });
      if (dev2) {
        this.devTypeList.push({
          name: "二路",
          id: 1,
        });
      }
      if (dev10) {
        this.devTypeList.push({
          name: "十路",
          id: 2,
        });
      }
      if (dev12) {
        this.devTypeList.push({
          name: "十二路",
          id: 3,
        });
      }
      this.tabs = this.devTypeList[0].id;
    },
  },
  methods: {
    changeTabs(index) {
      this.tabs = index;
    },
    submit() {
      Promise.all([
        this.$refs["form2"].check(),
        this.$refs["form10"].check(),
        this.$refs["form12"].check(),
      ])
        .then(() => {
          this.$confirm("是否确认提交?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            window.localStorage.setItem("devparams2", JSON.stringify(this.form2));
            window.localStorage.setItem("devparams10", JSON.stringify(this.form10));
            window.localStorage.setItem("devparams12", JSON.stringify(this.form12));
            this.setDevParams();
          });
        })
        .catch((err) => {
          this.$message.error(err + "类型参数有误，请仔细检查所填参数！！！");
        });
    },
    setDevParams() {
      this.showPop();
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.batchParams,
          method: "post",
          noShowLoading: true,
          params: {
            deviceCodes: this.devCodes,
            jdParam: this.form2 || null,
            yd10Param: this.form10 || null,
            yd12Param: this.form12 || null,
          },
        })
        .then((res) => {
          this.$refs["aPoppup"].close();
          if (res.result.code == 0) {
            this.dialogVisible = false;
            this.$emit("paramsSet", res.result.data);
          } else {
            this.$message.error(res.result.message);
          }
        })
        .catch(() => {
          this.$refs["aPoppup"].close();
        });
    },
    // 显示弹窗
    showPop() {
      this.$refs["aPoppup"].open({
        title: "参数下发中",
        subTitle: "预计等待时间1分30秒",
        showConfirm: true,
        showCancel: false,
        confirmText: "确认",
        cancelText: "取消",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebf0f5;
}
.tab-item {
  margin-right: 48px;
  padding-bottom: 14px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.tab-item:hover {
  color: #409eff;
}
.tabsAc {
  border-bottom: 2px solid #007aff;
}
.inputBox {
  width: 376px;
}
/deep/ .el-checkbox__label {
  display: none;
}
/deep/ .el-form-item__label {
  line-height: 22px;
  font-size: 14px;
}
.tips {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffa900;
  line-height: 22px;
}
.progress {
  width: 205px;
  height: 7px;
  border-radius: 4px;
  margin: 13px 0 24px;
  background: #b9b9b9;
}
.progress-item {
  width: 100%;
  height: 7px;
  border-radius: 4px;
  background: #007aff;
  animation: progressPop 10s;
  -webkit-animation: progressPop 5s; /* Safari and Chrome */
}
/* 动画 */
@keyframes progressPop {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@-webkit-keyframes progressPop /* Safari and Chrome */ {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>
