<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <div class="projectDetail">
      <div class="projectDetail-top a-flex-csbfs">
        <div class="a-flex-rfsfs">
          <span class="a-fs-16 a-fw-700 a-ff-dm">{{ detail.name }}</span>
        </div>
        <div class="a-mt-16 a-flex-rsbc a-flex-wrap a-w-100">
          <div class="projectDetail-top-item">
            <div class="wa pdr16 fontC17">商户全称</div>
            <span>{{ detail.name }}</span>
          </div>
          <div class="projectDetail-top-item">
            <div class="wa pdr16 fontC17">商户类型</div>
            <span>{{ detail.newTypeText }}</span>
          </div>
          <div class="projectDetail-top-item">
            <div class="wa pdr16 fontC17">法人/负责人</div>
            <span>{{ detail.repName }}</span>
          </div>
          <div class="projectDetail-top-item">
            <div class="wa pdr16 fontC17">营业执照</div>
            <div class="a-flex-rfsc">
              <le-btn-preview-img
                v-if="detail.certImagePath"
                :img-list="[detail.certImagePath]"
              ></le-btn-preview-img>
            </div>
          </div>
          <div class="projectDetail-top-item">
            <div class="wa pdr16 fontC17">创建时间</div>
            <span>{{ detail.createTimeText }}</span>
          </div>
          <div class="projectDetail-top-item">
            <div class="wa pdr16 fontC17">首次收益时间</div>
            <span>{{ detail.firstIncomeTime }}</span>
          </div>
          <div class="projectDetail-top-item"></div>
        </div>
        <div class="franchisee-statistics">
          <div class="franchisee-box">
            <div class="franchisee-title">账户可用余额(元)</div>
            <div class="franchisee-num">
              {{ "￥" + config.numFormat(detail.balance) }}
            </div>
          </div>
          <div class="franchisee-box">
            <div class="franchisee-title-o">冻结金额(元)</div>
            <div class="franchisee-num-o">
              {{ "￥" + config.numFormat(detail.balanceLock) }}
            </div>
          </div>
          <div class="franchisee-box">
            <div class="franchisee-title-o">待入账(元)</div>
            <div class="franchisee-num-o">
              {{ "￥" + config.numFormat(detail.balanceWaitConfirm) }}
            </div>
          </div>
        </div>
        <div class="a-mt-32 projectDetail-top-tabs a-w-100">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="收益统计" name="tjxx">
              <div class="pdtb10">
                <le-date-change-time
                  :active-t.sync="activeT"
                  :start-time.sync="startTime"
                  :end-time.sync="endTime"
                ></le-date-change-time>
              </div>
              <div class="tjxx-echarts a-mt-5 a-w-100 projectDetail-box">
                <div class="tjxx-echarts-title a-fw-700">{{ tjxxTimeText[activeT] }}收益趋势</div>
                <div class="tjxx-echarts-box">
                  <div class="a-flex-rfsfs a-w-100">
                    <div class="a-flex-cfsfs">
                      <span class="a-c-normal">总交易额(元)</span>
                      <div v-if="detailAmout" class="a-flex-rfsc a-mt-10">
                        <div class="a-fw-700">
                          <span class="a-fs-16">&yen;</span>
                          <span class="a-fs-20">
                            {{
                              config.numFormat(detailAmout ? detailAmout.thisTimeOrderAmount : 0)
                            }}
                          </span>
                        </div>
                        <span class="a-fs-12 a-c-blue a-ml-5">
                          较上{{ tjxxTimeText[activeT] }}
                          {{
                            detailAmout.thisTimeOrderAmount - detailAmout.lastTimeOrderAmount >= 0
                              ? "+"
                              : ""
                          }}
                          {{
                            config.numFormat(
                              detailAmout
                                ? detailAmout.thisTimeOrderAmount - detailAmout.lastTimeOrderAmount
                                : 0
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div class="a-flex-cfsfs a-ml-49">
                      <span class="a-c-normal">总订单量</span>
                      <div v-if="detailAmout" class="a-flex-rfsc a-mt-10">
                        <div class="a-fw-700">
                          <span class="a-fs-16">&yen;</span>
                          <span class="a-fs-20">
                            {{ detailAmout ? detailAmout.thisTimeOrderNum : 0 }}
                          </span>
                        </div>
                        <span class="a-fs-12 a-c-blue a-ml-5">
                          较上{{ tjxxTimeText[activeT] }}
                          {{
                            detailAmout.thisTimeOrderNum - detailAmout.lastTimeOrderNum >= 0
                              ? "+"
                              : ""
                          }}
                          {{
                            detailAmout
                              ? detailAmout.thisTimeOrderNum - detailAmout.lastTimeOrderNum
                              : 0
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div id="mychart" style="width: auto; height: 350px; margin-top: 34px"></div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="自营站点" name="htxx">
              <div class="bgC1 w100p pd24 bodr12">
                <site-view :company-id="companyId" :is-search-agency="0"></site-view>
              </div>
            </el-tab-pane>
            <el-tab-pane label="分成站点" name="fcxx">
              <div class="bgC1 w100p pd24 bodr12">
                <site-view :company-id="companyId" :is-search-agency="1"></site-view>
              </div>
            </el-tab-pane>
            <el-tab-pane label="设备总览" name="dfxx">
              <div>
                <device-view :names="activeName" :company-id="companyId"></device-view>
              </div>
            </el-tab-pane>
            <el-tab-pane label="员工列表" name="zdxx">
              <div class="bgC1 w100p pd24 bodr12">
                <staffe-view :company-id="companyId"></staffe-view>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="合同变更记录" name="htbgjl">
							<div class="bgC1 w100p pd24 bodr12" v-if="agencyDetail">
								<ContractView :names="activeName" :agencyId="agencyDetail.id"></ContractView>
							</div>
						</el-tab-pane> -->
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../../src/utils/util";
import LeBtnPreviewImg from "../components/poppup/le-btn-preview-img.vue";
import DeviceView from "./child/deviceView.vue"; //设备列表
import SiteView from "./child/siteView.vue"; //站点列表
import StaffeView from "./child/staffeView.vue"; //员工列表
export default {
  components: {
    SiteView,
    DeviceView,
    StaffeView,
    LeBtnPreviewImg,
  },
  data() {
    return {
      config: config, //公共方法集

      companyId: "",
      detail: {},
      activeT: 1,
      activeName: "tjxx",
      tjxxTimeText: ["周", "月", "年", "时间段"],
      // 收益统计
      startTime: "", //收益统计 时间筛选-start
      endTime: "", //收益统计 时间筛选-end
      totalParams: {},
      detailAmout: null, //统计数据
      //加盟合同
      agencyDetail: null,

      tjxxTime: 0,
      input2: "",
      modeltype: 2,

      agencyId: "",
    };
  },
  watch: {
    startTime: {
      handler(val) {
        if (val && !!this.companyId) {
          this.getTotal();
          this.getChartData();
        }
      },
    },
  },
  created() {
    this.companyId = parseInt(this.$route.query.code);
    this.getDetail();
  },
  mounted() {
    // this.echartsInit()
  },
  methods: {
    //tab切换
    handleClick(tab) {
      if (tab.index == "0") {
        //收益统计
        this.getTotal();
        this.getChartData();
      }
    },
    //加盟商信息详情
    getDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getOtherCompanyInfoNoCache,
          params: {
            companyId: parseInt(this.companyId),
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.detail = res.result.data;
            this.getTotal();
            this.getChartData();
            // this.getAgencyDetail();
          }
        });
    },

    //收益统计
    getTotal() {
      this.totalParams["queryStartDate"] = this.startTime;
      this.totalParams["queryEndDate"] = this.endTime;
      this.totalParams.companyId = this.companyId;
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatOrderAmountTotal,
          method: "post",
          params: this.totalParams,
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.detailAmout = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    // 获取图表数据
    getChartData() {
      // 如果存在日期选择区间则使用该日期选择区间，如果不存在则使用当前日期前30天作为日期选择区间
      let startDate = "";
      let endDate = "";
      if (this.startTime && this.endTime) {
        startDate = this.startTime;
        endDate = this.endTime;
      } else {
        endDate = this.$Utils.getSomeMonth().endtime;
        startDate = this.$Utils.getSomeMonth().starttime;
      }
      this.totalParams.queryStartDate = startDate;
      this.totalParams.queryEndDate = endDate;
      this.totalParams.companyId = this.companyId;
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatOrderAmountList,
          method: "post",
          params: this.totalParams,
        })
        .then((res) => {
          if (res.result.code == 0) {
            const xAxis = [];
            const dataTrading = [];
            const dataOrder = [];
            res.result.data.map((item) => {
              xAxis.push(item.fullDateText);
              dataTrading.push(Number((item.orderAmount / 100).toFixed(2)));
              dataOrder.push(item.orderNum);
            });
            // dataTrading[0] = 0.1
            this.drawChart(xAxis, dataTrading, dataOrder);
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    //echarts图表
    drawChart(xAxis = [], dataTrading = [], dataOrder = []) {
      //横坐标, 交易金额，订单数量
      const chartDom = document.getElementById("mychart");
      const option = {
        tooltip: {
          trigger: "axis",
          padding: 0,
          backgroundColor: "transparent",
          formatter: (item) => {
            const htmlContxt =
              `<div class="a-flex-cfsfs" style='padding:10px 18px;background:#FFFFFF;box-shadow: 0px 5px 21px 0px rgba(139,147,167,0.25);border-radius:6px'>
							<span style='font-size: 14px;color: #606366;'>` +
              item[0].name +
              `</span>` +
              `<span style='font-size: 17px;font-weight: 700;color: #303133;padding:10px 0'>￥` +
              this.setMoney(item[0].data) +
              `<span style='font-size: 16px;font-weight: 500;padding-left:5px'>元</span></span>` +
              `<span style='font-size: 16px;color: #303133;'>` +
              item[1].data +
              `<span style='font-size: 14px'>笔</span></span></div>`;
            return htmlContxt;
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "36px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          min: 0,
          axisLine: {
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#999999",
            showMaxLabel: true,
            formatter: function (value) {
              const arr = value.split("-");
              return arr[1] + "-" + arr[2];
            },
          },
          data: xAxis,
        },
        yAxis: {
          type: "value",
          min: 0,
          max: "dataMax",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "#EEEEEE",
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: dataTrading,
            type: "line",
            lineStyle: {
              normal: {
                color: "#FFA900",
                width: 4,
              },
            },
            smooth: true,
          },
          {
            data: dataOrder,
            type: "line",
            lineStyle: {
              normal: {
                color: "#6699FF",
                width: 4,
              },
            },
            smooth: true,
          },
        ],
      };
      const myChart = this.$echarts.init(chartDom);
      myChart.setOption(option, true);
    },
    download(url) {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          //测试链接console.log(a.href)
          a.download = "fileName.xlsx"; // 下载文件的名字
          document.body.appendChild(a);
          a.click();
        });
    },
    setMoney(val) {
      let num = Number(val);
      if (!num) {
        //等于0
        return num + ".00";
      } else {
        //不等于0
        // num = Math.round((num)*100)/10000;
        // num = num.toFixed(2);
        num += ""; //转成字符串
        const reg =
          num.indexOf(".") > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g; //千分符的正则
        // console.log(num.indexOf('.')>-1)
        return num.replace(reg, "$1,"); //千分位格式化
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.projectDetail {
  font-size: 14px;
  height: 100%;
  overflow-y: auto;

  > div {
    border-radius: 4px;
  }

  &-box {
    border-radius: 4px;
    background: #fff;
  }

  &-top {
    background: #fff;
    padding: 24px;
    padding-bottom: 64px;
    position: relative;

    &-item {
      display: flex;
      flex-direction: row;
      width: 33.3%;
      margin-top: 16px;
      font-size: 14px;
      line-height: 22px;

      > div {
        width: 116px;
      }
    }

    &-tabs {
      position: absolute;
      top: 267px;
      left: 0;

      // &-box {
      //     background: #fff;
      //     width: 100%;
      //     border-radius: 4px;
      // }

      .tjxx {
        &-tabs {
          span {
            border: 1px #dadde0 solid;
            border-left: 1px #fff solid;
            background: #fff;
            height: 32px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            width: 64px;
            text-align: center;
            cursor: pointer;

            &:hover {
              background: #f9f9f9;
            }

            &:nth-of-type(1) {
              border-radius: 4px 0 0 4px;
              border-left: 1px #dadde0 solid;
            }

            &:nth-last-of-type(1) {
              border-radius: 0 4px 4px 0;
            }

            &.active {
              border: 1px solid #007aff;
              color: #007aff;
            }
          }
        }

        &-echarts {
          &-title {
            width: 100%;
            padding: 16px 25px;
            border-bottom: 1px solid #ebf0f5;
            font-size: 16px;
          }

          &-box {
            padding: 15px 24px 10px;
          }
        }
      }

      .fcbl {
        padding: 24px;
        background: #fff;
        border-radius: 4px;

        &-table {
          margin-top: 16px;
          width: 100%;
          border: 1px solid #dadde0;
          padding: 0 16px 24px;

          > div {
            width: 100%;

            &:nth-of-type(1) {
              border: none;
              padding: 24px 0;
            }

            &:nth-of-type(2) {
              background: #f5f7fa;
            }

            padding: 12px 0;
            border-bottom: 1px solid #fafbfc;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;

            > * {
              width: 50%;
              text-align: center;
            }
          }
        }
      }

      .htxx {
        background: #fff;
        border-radius: 4px;

        > div {
          width: 33.33%;
          margin-bottom: 16px;

          a {
            text-decoration: none;
            color: #007aff;
            margin-left: 8px;
          }
        }
      }
    }
  }

  /deep/ .el-tabs__item {
    // color: #007aff;
    font-size: 14px;
  }

  /deep/ .el-tabs__item.is-active {
    color: #007aff;
    font-weight: bold;
  }

  /deep/ .el-tabs__nav-wrap::after {
    background: none !important;
  }

  /deep/ .el-tabs__header {
    margin-left: 24px;
  }
}

.franchisee {
  &-statistics {
    width: 100%;
    background: linear-gradient(90deg, #f5f7fa 0%, #fbfcfd 100%);
    border-radius: 4px;
    padding: 16px 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
  }

  &-box {
    width: 33%;
  }

  &-title {
    font-size: 14px;
    color: #303133;
    margin-bottom: 8px;
  }

  &-title-o {
    font-size: 14px;
    color: #606366;
    margin-bottom: 13px;
  }

  &-num {
    font-size: 24px;
    color: #007aff;
    font-weight: 600;
  }

  &-num-o {
    font-size: 14px;
    color: #303133;
  }
}

.w90p {
  width: 90%;
}

.franchisee-lv {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 4px;
  border: 1px solid #ffa900;
  font-size: 12px;
  font-weight: 400;
  color: #ffa900;
}
</style>
