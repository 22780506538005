<template>
  <div>
    <le-pagview :page-param="pageParam" @setData="setTableData">
      <el-table :data="tableData" :highlight-current-row="true">
        <el-table-column prop="name" label="站点名称" min-width="180"></el-table-column>
        <el-table-column prop="address" label="站点地址" min-width="180"></el-table-column>
        <el-table-column
          prop="hisOrderAmount"
          label="历史营业额(元)"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="todayOrderAmount"
          label="今日营业额(元)"
          min-width="80"
        ></el-table-column>
        <el-table-column prop="todayOrderNum" label="今日订单数" min-width="80"></el-table-column>
        <el-table-column
          prop="onlineDeviceNum"
          label="在线/总数(台)"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="usingDeviceNum"
          label="正在充电人数"
          min-width="80"
        ></el-table-column>
        <el-table-column prop="name" label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="详情" placement="top">
              <img
                src="@/assets/icon/option-detail.png"
                class="a-wh-16"
                @click="toStationInfo(scope.row)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <template slot="footerLabel">
        <div class="a-flex-rfsc"></div>
      </template>
    </le-pagview>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
    },
    isSearchAgency: {
      type: Number,
      default: 0, //是否查询代理站点 0:不查询 1:只查询代理站点 2:自营代理全查
    },
  },
  data() {
    return {
      pageParam: null,
      optionsProject: [], //加盟商字典
      tableData: [], //数据列表
    };
  },
  watch: {
    companyId: {
      handler(val) {
        if (val) {
          this.pageParam = {
            url: this.$Config.apiUrl.getStationInfoList,
            method: "post",
            params: {
              isSearchAgency: this.isSearchAgency,
              searchCompanyId: this.companyId,
            },
            freshCtrl: 1,
          };
        }
      },
      immediate: true,
    },
    isSearchAgency: {
      handler(val) {
        if (val) {
          this.pageParam = {
            url: this.$Config.apiUrl.getStationInfoList,
            method: "post",
            params: {
              isSearchAgency: this.isSearchAgency,
              searchCompanyId: this.companyId,
            },
            freshCtrl: 1,
          };
        }
      },
      immediate: true,
    },
  },
  methods: {
    setTableData(data) {
      const datas = data ? data : [];
      this.tableData = datas.map((res) => {
        return {
          ...res,
          hisOrderAmount: res.hisOrderAmount ? (res.hisOrderAmount / 100).toFixed(2) : 0.0,
          todayOrderAmount: res.todayOrderAmount ? (res.todayOrderAmount / 100).toFixed(2) : 0.0,
        };
      });
    },
    toStationInfo(item) {
      this.$router.push({
        path: "/station/station-detail",
        query: {
          stationId: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
